import React from 'react';
import styled from '@emotion/styled';
import {
  FaShieldAlt as ShieldIcon,
  FaFacebookF as Facebook,
  FaLinkedinIn as LinkedIn,
} from 'react-icons/fa';
import { FiInstagram as Instagram } from 'react-icons/fi';
import { useStaticQuery, graphql } from 'gatsby';
import { MarkerIcon } from './SVG';
import { Content, Text, Spacing, Link, devices, Stack } from './common';
import {
  Address as AddressType,
  Service,
  Links as LinkType,
} from '../@types/types';
import WhiteLogo from '../../static/assets/logo_white.png';
import theme from '../theme';

type Props = {
  address: AddressType[];
  services: Service[];
};

type SocialMedia = {
  Name: string;
  Url: string;
};

const Footer = ({ address, services }: Props) => {
  const links = [
    { name: 'Asiantuntijat', path: '/asiantuntijat' },
    { name: 'Aatoslaiseksi?', path: '/tyopaikat' },
    { name: 'Koulutukset', path: '/koulutukset' },
    { name: 'UKK', path: '/ukk' },
    { name: 'Blogi', path: '/blogi' },
    { name: 'Yritys', path: '/yritys' },
    { name: 'Tietosuoja', path: '/tietosuoja' },
    { name: 'Yhteys', path: '/yhteys' },
  ];

  const { socialMedia } = useStaticQuery(
    graphql`
      query {
        socialMedia: allStrapiSocialMedia {
          nodes {
            Name
            Url
          }
        }
      }
    `
  );

  return (
    <Container>
      <Background>
        <ContentContainer>
          <Column>
            <Logo src={WhiteLogo} alt="Aatos Perheklinikka" />
            <AddressDetails>
              <MarkerIcon fill={theme.colors.white} />
              <Address>
                {address.map(
                  ({ street, postalCode, city }: AddressType, index) => (
                    <div key={index}>
                      <Text variant="body" color="white">
                        {street}
                      </Text>
                      <Text variant="body" color="white">
                        {postalCode} {city}
                      </Text>
                    </div>
                  )
                )}
                <Spacing axis="y" amount="large" />
                <Text variant="body" color="white">
                  Katutaso
                </Text>
                <Text variant="body" color="white">
                  3. kerros
                </Text>
                <Text variant="body" color="white">
                  6. kerros
                </Text>
                <Spacing axis="y" amount="large" />
                <Text variant="body" color="white">
                  varaa aika 010 325 4540
                </Text>
                <Text variant="body" color="white">
                  palvelut@aatosklinikka.fi
                </Text>
                <Spacing axis="y" amount="large" />
                <SocialMediaContainer>
                  {socialMedia.nodes.map(
                    (social: SocialMedia, index: number) => {
                      const Icon =
                        social.Name.toLowerCase() === 'facebook'
                          ? Facebook
                          : social.Name.toLowerCase() === 'instagram'
                          ? Instagram
                          : social.Name.toLowerCase() === 'linkedin'
                          ? LinkedIn
                          : undefined;
                      if (!Icon) return null;
                      return (
                        <a
                          key={index}
                          href={social.Url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon size={24} color="white" />
                        </a>
                      );
                    }
                  )}
                </SocialMediaContainer>
              </Address>
            </AddressDetails>
          </Column>
          <ColumnContainer>
            <Column>
              <Text variant="body" color="white">
                Palvelut
              </Text>
              {services.map(({ name, slug }: Service, index) => (
                <LinkContainer to={`/palvelut/${slug}`} key={index}>
                  <ServiceLinkItem variant="body" color="white">
                    {name}
                  </ServiceLinkItem>
                </LinkContainer>
              ))}
              <LinkContainer to={`/palvelut#prices`}>
                <ServiceLinkItem variant="body" color="white">
                  Hinnasto
                </ServiceLinkItem>
              </LinkContainer>
            </Column>
            <Column>
              {links.map(({ name, path }: LinkType, index) => (
                <LinkContainer to={path} key={index}>
                  <LinkItem variant="body" color="white">
                    {name}
                  </LinkItem>
                </LinkContainer>
              ))}
            </Column>
          </ColumnContainer>
        </ContentContainer>
      </Background>
    </Container>
  );
};

export default Footer;

const Container = styled.div``;

const Background = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.darkBlue};
`;

const ContentContainer = styled(Content)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing.xxlarge};

  @media ${devices.laptop} {
    flex-direction: column;
  }

  @media ${devices.tablet} {
    padding: ${(props) => props.theme.spacing.large};
  }

  @media ${devices.mobileL} {
    padding: ${(props) => props.theme.spacing.medium};
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;

  @media ${devices.laptop} {
    width: 100%;
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: ${(props) => props.theme.spacing.large};
  box-sizing: border-box;

  @media ${devices.laptop} {
    width: 100%;
    padding: ${(props) => props.theme.spacing.medium} 0px;
  }
`;

const Logo = styled.img`
  width: 305px;
  margin: 10px 20px 0px 9px;
  transform: translateX(-50px);
`;

const AddressDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 243px;
  margin-top: ${(props) => props.theme.spacing.xxlarge};

  @media ${devices.mobileL} {
    width: 224px;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServiceLinkItem = styled(Text)`
  margin: ${(props) => props.theme.spacing.default} 0px 0px
    ${(props) => props.theme.spacing.large};
`;

const LinkItem = styled(Text)`
  margin: 0px 0px ${(props) => props.theme.spacing.default};
`;

const LinkContainer = styled(Link)`
  text-decoration: none;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
